import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashbordService {

  PrRejeter: any;
  NBProduit: any;
  NBmatierepremiére: any;

  constructor() { }

}
