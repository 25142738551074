
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CONFIG } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from '../components/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  public roles: string[];
  CONFIG: string;
  authorities = [];
  roleAs: string;




  constructor(private httpClient: HttpClient, private route: Router) {

  }


  isLoginSubject = new BehaviorSubject<boolean>(this.isAuthentificated())


  addUser(user) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    this.httpClient.post<any>(CONFIG.URL + 'users/addUser', user, { headers: headers }).subscribe(

      (msg) => {
        console.log(msg),
          location.reload()
      },
      (error) => console.log(error)
    );
  }
  getUser() {
    const user = this.httpClient.get(this.CONFIG + 'users/allUsers');
    return user;
  }
  
  login(log){
   
    const webToken = localStorage.getItem('webToken');

    let notification={
     "tokenNotification":webToken,
     "typeDevice": "webToken"
     }
 
     log=Object.assign({},log,notification);
  this.httpClient.post<any>(`${CONFIG.URL}auth/login`,log) .subscribe((response:any) => {
  
     if (response.token) {
     
      console.log(response);
      localStorage.setItem('email', log.email);
      localStorage.setItem('token',response.token );
      localStorage.setItem('user',response.roles[0].name);
    
    const role = response.roles[0].name;

      console.log(role);
      if(role==='ADMIN'){
        this.route.navigateByUrl('/dashbord')

        } else if (role =='ADHERENT') {
          localStorage.setItem('email', log.email);
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', response.roles[0].name);

          this.route.navigateByUrl('/profilee')
        }
        else if (role =='GUEST') {
          this.route.navigateByUrl('/')


        }
        this.isLoginSubject.next(true);
      } else {
        this.route.navigateByUrl('/login')
      }


      //localStorage.setItem('role', JSON.stringify(Role));

      //let data = JSON.parse(localStorage.getItem('roles'));

      //console.log(Role);




      (error) => console.log(error.message)
    })
  }

  public isAuthentificated(): boolean {
    const token = localStorage.getItem('token');
    if (token == null) {
      return false;
    } else {
      return true;
    }
  }

  public UserAuthentificated(): boolean {
    return true;
  }

  logout() {
    console.log("ok");
    this.isLoginSubject.next(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    this.route.navigateByUrl('/login');

  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  getRole() {
    this.roleAs = localStorage.getItem('user');
    return this.roleAs;
  }


  reset_password(email) {
    return this.httpClient.get(`${CONFIG.URL}reset_password`, email)
  }
}
