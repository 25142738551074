export class Adress {
       id: any;
    
        city_name: string;
        
        home_adress: string;
        gouvernorat: string;
        work_adress:string;
        zip: string;
     

      
}
