import { AuthServiceService } from './../../services/auth-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashbordService } from './dashbord.service';
import Chart from "chart.js/auto";

@Component({
  selector: 'app-dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.scss']
})
export class DashbordComponent implements OnInit {

  value: number = 0;
  thumbnail: any ;
  dashobardLoaded: boolean  = false;
  profil : any ;
  messageService: any;

  constructor(private authService:AuthServiceService,private dashbordservice : DashbordService,private router: Router) { }

 
  ngOnInit() {
    let interval = setInterval(() => {
      this.value = this.value + Math.floor(Math.random() * 10) + 1;
      if (this.value >= 100) {
          this.value = 100;
          this.messageService.add({severity: 'info', summary: 'Success', detail: 'Process Completed'});
          clearInterval(interval);
      }
  }, 2000);
}

logOutBar(){
  this.authService.logout();
 
}

  }
