import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { ServiceThreeComponent } from './components/pages/service-three/service-three.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';

import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';

import { PricingComponent } from './components/pages/pricing/pricing.component';
import { RecentProjectComponent } from './components/pages/recent-project/recent-project.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TeamComponent } from './components/pages/team/team.component';

import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';

import { RegisterComponent } from './components/pages/register/register.component';
import { DetailsComponent } from './components/pages/details/details.component';
import { AdresseComponent } from './components/pages/adresse/adresse.component';
import { ForgotloginComponent } from './components/pages/forgotlogin/forgotlogin.component';
import { DashbordComponent } from './dash/dashbord/dashbord.component';
import { ProfileComponent } from './dash/profile/profile.component';
import { GestionadherentsComponent } from './dash/gestionadherents/gestionadherents.component';
import { GestioneventComponent } from './dash/gestionevent/gestionevent.component';
import { ProfileeComponent } from './Adhèrents/profilee/profilee.component';
import { AuthGuard } from './auth.guard';
import { ChangermdpComponent } from './components/pages/changermdp/changermdp.component';

import { ValidercodeComponent } from './validercode/validercode.component';

import { Gestionadherent1Component } from './dash/gestionadherent1/gestionadherent1.component';

import { MapComponent } from './map/map.component';

import { ChatComponent } from './chat/chat.component';

import { Map1Component } from './map1/map1.component';















const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'service', component: ServiceComponent},
    {path: 'service-two', component: ServiceTwoComponent},
    {path: 'service-three', component: ServiceThreeComponent},
    {path: 'service-details', component: ServiceDetailsComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'recent-project', component: RecentProjectComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'team', component: TeamComponent},
    {path: 'contact', component: ContactComponent},
    {path:'login' , component:LoginComponent},  
    {path:'Register' , component: RegisterComponent},
    {path:'details' , component: DetailsComponent},
    {path:'adresse' , component: AdresseComponent},
    {path:'forgotlogin',component:ForgotloginComponent},

    {path:'map1',component:Map1Component},
   
    {path: 'gestionadherents', component: GestionadherentsComponent},
    {path: 'gestionevent', component: GestioneventComponent },
    {path:'authGuard',component: AuthGuard},
    {path:'changemdp',component:ChangermdpComponent},
    {path:'validercode',component:ValidercodeComponent},
    {
      path: 'reset/finish',
      component:ChangermdpComponent ,
    },
   

    {path: 'gestionadherent1/:id', component: Gestionadherent1Component },

    {path: 'map', component: MapComponent },

    {path: 'chat', component: ChatComponent},


   {path:'authGuard',component: AuthGuard},
   {path:'changemdp',component:ChangermdpComponent},
   {
    path: 'reset/finish',
    component:ChangermdpComponent ,
   },
   {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
   {
    path: "profilee",
    component: ProfileeComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ADHERENT'
    }
  }, {
    path: 'profile',
     component: ProfileComponent,
     canActivate: [AuthGuard],
     data: {
       role: 'ADMIN'
     }
    },

  {
    path: "dashbord",
    component: DashbordComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
   
   
    // Here add new pages component

    {path: '**', component: ErrorComponent}, // This line will remain down from the whole pages component list
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }




