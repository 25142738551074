
import { Users } from './../../../users';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from './../../../services/auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  users = new Users();
  loginForm;
  submitted:Boolean=false;

  type_input: boolean;
  type_input3: boolean;
  type_input2: any;
  constructor(private authServiceService: AuthServiceService , private route: Router) { }

  ngOnInit(): void {
    this.loginForm= new FormGroup({
      email: new FormControl('',[Validators.required,Validators.email]),
      password: new FormControl('',[Validators.required])
      
  })
}
submitForm() {

this.submitted=true;
if (this.loginForm.invalid) {
  return ;
}
console.log(this.loginForm.value );

 this.authServiceService.login(this.loginForm.value)
  

 //this.route.navigateByUrl('/dashbord')
}



changeInput1() {
  this.type_input = !this.type_input;
}
changeInput2() {
  this.type_input2 = !this.type_input2;
}
changeInput3() {
  this.type_input3 = !this.type_input3;
}
}