export class Details {
   id!: any;
    cin!:any;
    date_de_naissance!:Date;
    nationalite!: string;
    profession!: string;
    sexe!: string;
    statut_social!:string;
  

    
   
}
