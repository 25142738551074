export class Users {
    id!:any;
    firstName!:string;
    lastName!:string;
    email!:string;
    password!:string;
    telephone!:string;
    resetPasswordToken!:string;
    
}
